import ClickAwayListener from '@mui/material/ClickAwayListener'
import { TooltipProps } from '@mui/material/Tooltip'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { UserMetaResource } from '@rallycry/api-suite-typescript/dist/models/UserMetaResource'
import { some } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { AchievementResource } from '@rallycry/api-suite-typescript/dist/models'
import Box from '@mui/material/Box'
import { ModalTrigger } from '../modal/ModalTrigger'
import { NotificationContainer } from './NotificationContainer'
import DisplayNewAchievements from './DisplayNewAchievements'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  RcIconButton,
  RcIconButtonProps
} from '@/components/molecules/interactive/RcIconButton'
import { RcTooltipButton } from '@/components/molecules/interactive/RcTooltipButton'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useSessionStorage } from '@/core/hooks/useLocalStorage'
import { useIsTablet } from '@/core/hooks/useMediaQueries'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useFirebaseMeta } from '@/entity/site/useFirebaseMeta'
import { useTwitchOnline } from '@/entity/site/useTwitchOnline'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserInboxMarker } from '@/entity/user/useUserInboxMarker'
import { useUserMeta } from '@/entity/user/useUserMeta'
import { dingle } from '@/core/animations'

export const useNotificationStyles = () => {
  return (theme: any) => ({
    color: `${theme.palette.getContrastText(
      theme?.palette.background.default
    )} !important`,
    backgroundColor: `${theme?.palette.background.default} !important`,
    border: `1px solid ${theme?.palette.divider}`,
    width: 300,
    height: 500,
    maxHeight: '90dvh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    padding: `0px !important`,
    marginLeft: `${theme.spacing(9)} !important`
  })
}

export const useHasTwitch = () => {
  const state = useSessionStorage({
    key: 'has-twitch-alert',
    defaultValue: false
  })
  const { cfgTwitchChannels } = useFeatures()
  const onlineTwitches = useTwitchOnline({
    request: { identifiers: (cfgTwitchChannels as string)?.split(',') }
  })

  useEffect(() => {
    // if any twitch channels are online, set hasTwitch to true
    if (
      onlineTwitches &&
      some(Object.values(onlineTwitches), (it: any) => !!it?.stream?.id)
    ) {
      state[1](true)
    }
  }, [state, onlineTwitches])

  return state
}

export const NotificationIcon = ({
  expanded,
  button,
  ...props
}: Partial<RcIconButtonProps> & { expanded?: boolean; button?: boolean }) => {
  const [achievement, setAchievement] = useState<
    AchievementResource | undefined
  >()
  const [open, setOpen] = useState(false)
  const { read: inboxMeta, marker, markAsRead } = useUserInboxMarker()
  const { account } = useUserAccount()
  const isMobile = useIsTablet()

  const style = useNotificationStyles()
  const [hasTwitch, setHasTwitch] = useHasTwitch()

  const { t } = useRcTranslation() // aria

  useEffect(() => {
    setOpen(false)
  }, [expanded])

  const handleClick = async () => {
    if (open) {
      await handleClose()
    } else {
      setOpen(true)
      await markAsRead()
    }
  }

  const handleClose = useCallback(async () => {
    if (open) {
      setOpen(false)
      setHasTwitch(false)
      await markAsRead()
    }
  }, [open, markAsRead, setHasTwitch])

  const { read: userMeta } = useUserMeta()

  const userMutate = userMeta.mutate
  const inboxMutate = inboxMeta.mutate
  useFirebaseMeta({
    entityType: EntityType.USERMETA,
    id: userMeta.data?.id?.toString(),
    mutator: (d: any) => {
      if (!d) return
      //handle old users who dont have a USER_META document
      // split off inboxSequenceValue from the rest of the meta to prevent excessive re-renders
      // split off expired from the rest of the meta to prevent excessive re-renders (not sure why its on the doc)
      const { inboxSequenceValue, expired, ...rest } = d
      return Promise.allSettled([
        userMutate((it: UserMetaResource) => ({ ...it, ...rest }), {
          revalidate: false
        }),
        inboxMutate(
          it => ({
            ...it,
            inbox: { ...it?.inbox, sequence: inboxSequenceValue }
          }),
          {
            revalidate: false
          }
        )
      ])
    },
    jitter: false
  })

  const sequence = marker?.inbox?.sequence || 0
  const readCount = marker?.readCount || 0
  const unreadCount = sequence - readCount

  if (!account?.id) return null

  const tooltipProps: Omit<TooltipProps, 'children'> = {
    open: open,

    disableInteractive: false,
    componentsProps: {
      tooltip: {
        sx: style
      }
    },
    placement: isMobile ? 'bottom' : 'right-start',
    title: (
      <RcSuspense skipError skipLoader>
        <NotificationContainer onAchievement={a => setAchievement(a)} />
      </RcSuspense>
    )
  }

  return (
    <>
      <ClickAwayListener touchEvent={false} onClickAway={handleClose}>
        {button ? (
          <RcTooltipButton
            badge={unreadCount > 0 || hasTwitch}
            aria-label={t('site.notifications.aria-label')}
            size='large'
            icon={['fal', 'bell']}
            onClick={handleClick}
            TooltipProps={tooltipProps}
            variant='text'
            className={`rc-navigation-button ${open ? 'active' : ''}`}
            disableFocusRipple
            disableRipple
            sx={dingle}
          >
            {expanded ? <RcTrans i18nKey='navigation.notifications' /> : null}
          </RcTooltipButton>
        ) : (
          <RcIconButton
            badge={unreadCount > 0 || hasTwitch}
            aria-label={t('site.notifications.aria-label')}
            size='small'
            icon={['fal', 'bell']}
            onClick={handleClick}
            TooltipProps={tooltipProps}
            IconProps={{ size: 'sm' }}
            disableTouchRipple
            sx={dingle}
            {...props}
          />
        )}
      </ClickAwayListener>
      <Box display='none'>
        <ModalTrigger
          open={!!achievement}
          modalProps={{
            noPadding: true,
            skipCloseButton: true,
            skipFullScreen: true,
            headerBackground: true
          }}
          onClose={() => setAchievement(undefined)}
        >
          <DisplayNewAchievements
            achievement={achievement}
            onComplete={() => setAchievement(undefined)}
          />
        </ModalTrigger>
      </Box>
    </>
  )
}

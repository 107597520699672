import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import Color from 'color'
import { useTime } from '@/core/hooks/useTime'
import { RcIcon } from '@/components/atoms/RcIcon'
import { toBase64 } from '@/core/toBase64'
import { borderBox } from '@/components/pages/Competition/components/bracket/Ladder/LadderBanner'
import { borderAnimation, swirlySx } from '@/core/animations'
import { useMatchmEntry } from '@/entity/matchm/useMatchmEntry'

const start = -75
const stop = 10

const slideSx = ({
  duration,
  name,
  inOut,
  ltr,
  top
}: {
  duration: number
  name: string
  inOut: boolean
  ltr: boolean
  top: string
}) => {
  return {
    position: 'absolute',
    top,
    width: '100dvw',
    height: 230,
    borderRadius: 1,
    animation: `${name} ${duration}s linear forwards`,
    backgroundImage: `linear-gradient(${
      (ltr ? 90 : -90) + (inOut ? 20 : -20)
    }deg, transparent, rgba(${inOut ? 0 : 255},0,${inOut ? 255 : 0},.2))`,
    backgroundRepeat: 'no-repeat',
    [`@keyframes ${name}`]: {
      from: {
        opacity: inOut ? 1 : 0.5,
        backgroundPosition: inOut ? '-100px' : '100px',
        transform: `translateX(${ltr ? start : -start - 10}%) skewX(${
          ltr ? 45 : -45
        }deg)`
      },
      to: {
        opacity: inOut ? 0.0 : 1,
        backgroundPosition: 0,
        transform: `translateX(${ltr ? stop : -stop - 10}%) skewX(${
          ltr ? 45 : -45
        }deg)`
      }
    }
  }
}

export const MatchFoundModal = ({
  target,
  onClose
}: {
  target?: Date
  onClose: () => void
}) => {
  const { getNow } = useTime()
  const { matchm, accept, decline } = useMatchmEntry()
  const [duration, setDuration] = useState(1)
  const [remaining, setRemaining] = useState(1)

  useEffect(() => {
    if (!target) return
    const now = getNow()
    const targetMoment = moment(target)
    setDuration(targetMoment.diff(now) / 1000)

    const tick = () => {
      const now = getNow()
      const countdownMillisecond = targetMoment.diff(now)
      setRemaining(countdownMillisecond)
    }

    tick()
    const timer = setInterval(tick, 1000)

    return () => clearInterval(timer)
  }, [target, getNow])

  const percentRemaining = remaining / duration / 1000
  const theme = useTheme()
  const image = `url(data:image/svg+xml;base64,${toBase64(
    borderBox(
      Color(theme.palette.primary.light).alpha(1).rgb().string(),
      Color('blue')
        .mix(Color('red'), 1 - percentRemaining)
        .alpha(0.3)
        .rgb()
        .string()
    )
  )})`

  return (
    <Box display='none'>
      <Modal open={!!target}>
        <Box
          position='relative'
          sx={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            backdropFilter: 'blur(6px)'
          }}
        >
          <Box
            sx={slideSx({
              duration,
              name: 'slide1',
              inOut: false,
              ltr: true,
              top: 'calc(50% - 157px)'
            })}
          />
          <Box
            sx={slideSx({
              duration,
              name: 'slide2',
              inOut: true,
              ltr: true,
              top: 'calc(50% - 157px)'
            })}
          />
          <Box
            sx={slideSx({
              duration,
              name: 'slide3',
              inOut: false,
              ltr: false,
              top: 'calc(50% - 13px)'
            })}
          />
          <Box
            sx={slideSx({
              duration,
              name: 'slide4',
              inOut: true,
              ltr: false,
              top: 'calc(50% - 13px)'
            })}
          />
          <Box
            sx={{
              position: 'absolute',
              width: '100dvw',
              height: 370,
              opacity: 0.4,
              top: 'calc(50% - 157px)',
              left: 0,
              backgroundImage: 'url(/dust.svg)',
              backgroundBlendMode: 'overlay',
              ...swirlySx
            }}
          />
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={5}
            sx={{
              opacity: 0.99,
              width: '100dvw',
              height: '100dvh'
            }}
          >
            {remaining > 0 ? (
              <Typography variant='h1'>Match Found!</Typography>
            ) : (
              <Typography variant='h1'>Timer expired</Typography>
            )}

            {remaining > 0 ? (
              <Typography variant='subtitle2' color='primary.main'>
                Auto decline in {Math.ceil(remaining / 1000)}s{' '}
              </Typography>
            ) : (
              <Typography variant='subtitle2' color='primary.main'>
                You have missed your chance to accept :(
              </Typography>
            )}
            <Box
              sx={{
                position: 'relative',

                transition: 'width 0.3s ease-out',
                '&:before': {
                  content: { xs: 'none', sm: '""' },
                  position: 'absolute',
                  width: 1000,
                  height: 86,
                  backgroundImage: image,
                  backgroundSize: 'cover',
                  top: `calc(50% - 43px)`,
                  right: remaining > 0 ? 303 : 140,
                  transition: 'right 0.5s',
                  transform: 'rotate(180deg)'
                },
                '&:after': {
                  content: { xs: 'none', sm: '""' },
                  position: 'absolute',
                  width: 1000,
                  height: 86,
                  backgroundImage: image,
                  backgroundSize: 'cover',
                  top: `calc(50% - 43px)`,
                  left: remaining > 0 ? 303 : 140,
                  transition: 'left 0.5s'
                }
              }}
            >
              <Stack
                direction='row'
                spacing={3}
                justifyContent='center'
                alignItems='center'
                sx={{
                  py: 5
                }}
              >
                {remaining > 0 ? (
                  <>
                    <Box position={'relative'} p={0.5}>
                      <Box
                        sx={theme => ({
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          p: 0.5,
                          borderRadius: 15,
                          ...borderAnimation(theme)
                        })}
                      />
                      <Button
                        size='large'
                        onClick={async () => {
                          await accept(matchm!)
                          onClose()
                        }}
                        startIcon={<RcIcon icon={['fal', 'check']} size='xs' />}
                        sx={{
                          py: 3,
                          px: 10,
                          borderRadius: 15
                        }}
                      >
                        Accept
                      </Button>
                    </Box>

                    <Button
                      size='large'
                      color='secondary'
                      onClick={async () => {
                        await decline(matchm!)
                        onClose()
                      }}
                      startIcon={<RcIcon icon={['fal', 'times']} size='xs' />}
                      sx={{
                        py: 3,
                        px: 10,
                        borderRadius: 15
                      }}
                    >
                      Decline
                    </Button>
                  </>
                ) : (
                  <Button
                    size='large'
                    color='error'
                    onClick={onClose}
                    startIcon={<RcIcon icon={['fal', 'times']} size='xs' />}
                    sx={{
                      py: 3,
                      px: 10,
                      borderRadius: 15
                    }}
                  >
                    Declined
                  </Button>
                )}
              </Stack>
            </Box>
            <Typography variant='subtitle2' color='text.secondary'>
              Declining too many matches may place you in lower priority queue.
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </Box>
  )
}

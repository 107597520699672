import {
  BracketMatchControllerApi,
  MatchmTicketControllerApi
} from '@rallycry/api-suite-typescript'
import { EntryDocument } from '@rallycry/social-api-typescript'
import { EntrantControllerApi } from '@rallycry/social-api-typescript/dist/apis'
import { doc, onSnapshot } from 'firebase/firestore'
import useSWRSubscription, { SWRSubscriptionOptions } from 'swr/subscription'
import { EntityOptions } from '../useEntity'
import { useController, useSocialController } from '@/core/hooks/useSWRApi'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const useMatchmEntry = (options?: EntityOptions) => {
  const { firestore } = useFirebase()
  const { ctrl: ticketCtrl } = useController(MatchmTicketControllerApi)
  const { ctrl: matchmCtrl } = useSocialController(EntrantControllerApi)
  const { ctrl: bracketCtrl } = useController(BracketMatchControllerApi)

  const matchmId = options?.idOrKey

  const matchm = useSWRSubscription(
    [matchmId, 'useMatchmEntry'],
    ([matchmId], { next }: SWRSubscriptionOptions<EntryDocument, Error>) => {
      if (!matchmId) {
        next(null, undefined)
        return () => {}
      }

      const docKey = `Matchm_Entry/${matchmId}`

      const ref = doc(firestore, docKey)
      const unsub = onSnapshot(
        ref,
        async doc => {
          next(null, doc.data() as EntryDocument)
        },
        err => next(err)
      )

      return unsub
    },
    { suspense: false }
  )

  const enter = async (externalId: string, entryId: number) => {
    const ticket = await ticketCtrl().obtainMatchmTicket({
      externalId,
      ObtainTicketCommand: { entryId, enhance: true }
    })
    await matchmCtrl({ ticket: ticket.ticket }).enterEntrant({
      matchmId: externalId?.toString()!,
      actorId: entryId?.toString()!,
      entrantEnterCommand: {
        name: ticket.name,
        members: ticket.members?.map(it => ({
          ...it,
          id: it.id?.toString()!,
          externalId: it.externalId?.toString()!
        }))
      }
    })
  }

  const accept = async (matchm: EntryDocument) => {
    const externalId = matchm?.matchm?.id
    const entryId = matchm?.actor?.id
    const ticket = await ticketCtrl().obtainMatchmTicket({
      externalId,
      ObtainTicketCommand: { entryId: Number(entryId) }
    })
    await matchmCtrl({ ticket: ticket.ticket }).acceptEntrantMatch({
      matchmId: externalId,
      actorId: entryId
    })
  }

  const decline = async (matchm: EntryDocument) => {
    const externalId = matchm?.matchm?.id
    const entryId = matchm?.actor?.id
    const ticket = await ticketCtrl().obtainMatchmTicket({
      externalId,
      ObtainTicketCommand: { entryId: Number(entryId) }
    })
    await matchmCtrl({ ticket: ticket.ticket }).declineEntrantMatch({
      matchmId: externalId,
      actorId: entryId
    })
  }

  const leave = async (matchm: any) => {
    const externalId = matchm?.id?.split('_')[0]
    const entryId = matchm?.id?.split('_')[1]
    const ticket = await ticketCtrl().obtainMatchmTicket({
      externalId,
      ObtainTicketCommand: { entryId: Number(entryId) }
    })
    await matchmCtrl({ ticket: ticket.ticket }).leaveEntrant({
      matchmId: externalId,
      actorId: entryId
    })
  }

  const viewMatchDetails = async (externalId: string) =>
    bracketCtrl().readLadderBracketMatchByExternalId({
      externalId,
      expand: 'bracket{competition}'
    })

  return {
    enter,
    accept,
    decline,
    leave,
    matchm: matchm.data,
    matchmId,
    viewMatchDetails
  }
}

'use client'

import Container from '@mui/material/Container'
import { NavigationMobileV4 } from '../organisms/navigation/NavigationMobileV4'
import { Footer } from '@/components/organisms/site/Footer'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { Redirect } from '@/components/pages/CMS/dynamic/Redirect'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RootRoute } from '@/core/route-keys'
import { CkCnsnt } from '@/components/organisms/site/CkCnst'

export const UnauthenticatedLayout = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { user } = useFirebase()
  const { featSecureMode } = useFeatures()
  const { getPath } = useNavigation()

  if (featSecureMode && !user) {
    return <Redirect path={getPath({ root: RootRoute.Login })} replace />
  }

  return (
    <>
      <NavigationMobileV4 skipAlerts />

      <Container
        maxWidth='lg'
        sx={theme => ({
          marginTop: 0,
          '& .unauth-full-width': {
            marginBottom: -1,
            '& > .MuiPaper-root, .page-hero': {
              margin: 0,
              position: 'relative',
              borderRadius: 0,
              border: 'none',
              boxShadow: 'none'
            }
          },
          [theme.breakpoints.down('lg')]: {
            '& .page-header': {
              marginTop: 0,
              borderRadius: 0
            },
            '& .page-item': {
              borderRadius: 0
            },
            paddingLeft: 0,
            paddingRight: 0
          },
          [theme.breakpoints.up('lg')]: {
            '& .page-header': {
              marginTop: theme.spacing(16)
            },
            '& .hero-boxes': {
              width: '100vw',
              overflowX: 'visible !important',
              marginLeft: `calc((100dvw - ${
                theme.breakpoints.values.lg - 24
              }px) * -0.5)`
            },
            '& .page-hero': {
              height: 560,
              width: '100vw'
            },
            '& .page-hero-title': {
              height: 720
            },
            '& .unauth-full-width, footer': {
              '& .page-hero': {
                overflow: 'hidden'
              },
              '& .page-item': {},
              '& .page-item-content': {
                overflowX: 'visible'
              },
              '& .page-item-background': {
                display: 'block',
                position: 'absolute',
                zIndex: 0,
                left: `calc((100dvw - ${
                  theme.breakpoints.values.lg - 24
                }px) * -0.5)`,
                right: `calc((100dvw - ${
                  theme.breakpoints.values.lg - 24
                }px) * -0.5)`,
                top: 0,
                bottom: 0
              },
              '& .page-header': {
                left: `calc((100dvw - ${
                  theme.breakpoints.values.lg - 24
                }px) * -0.5)`,
                width: '100vw'
              },
              '& .page-header-content': {
                padding: `0px 32px 0px 32px`
              }
            }
          }
        })}
      >
        {children}
        {/* <CkCnsnt /> */}
        <Footer />
      </Container>
    </>
  )
}
